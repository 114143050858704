<template>
  <div class="banner-card" :style="{ 'background-image': $h.getStyleImage(data?.Image, 'BANNER', null, true) }" @click="onViewBanner">
    <div class="img-overlay"></div>
    <h3 v-if="data?.BannerName && data?.BannerName != ''">{{ $h.formatTrimString(data?.BannerName, 80) }}</h3>
    <p v-if="data?.Description && data?.Description != ''">{{ $h.formatTrimString(data?.Description, 120) }}</p>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { helpers } from "@/utils/helpers.js";

export default defineComponent({
  name: "BannerCardComponent",
  components: {},
  props: {
    data: Object,
    f7router: Object,
    f7route: Object,
  },
  setup(props) {
    const onViewBanner = () => {
      let bannerLink = props?.data?.Link;
      if (!bannerLink) return;

      if (bannerLink.indexOf("http") > -1) {
        return helpers.deepLinking(props.f7router, bannerLink);
      }

      if (bannerLink.indexOf("/") > -1) {
        return props.f7router.navigate(bannerLink);
      }

      if (props?.f7router?.routes?.length > 0) {
        let isFound = false;
        for (let item of props?.f7router?.routes) {
          if (item.name == bannerLink) {
            isFound = true;
            break;
          }
        }

        if (isFound) return props.f7router.navigate({ name: bannerLink });
        props.f7router.navigate({ name: "productViewPage", params: { code: bannerLink } });
      }
    };

    return { onViewBanner };
  },
});
</script>
